import React from 'react'
import facebookIcon from '../../../images/social-media/SM_Facebook.svg'
import instagramIcon from '../../../images/social-media/SM_Instagram.svg'
import linkedinIcon from '../../../images/social-media/SM_LinkedIn.svg'
import xingIcon from '../../../images/social-media/SM_XING.svg'
import { socialMedia } from './socialMedia.module.scss'

export default function SocialMedia(props) {
  const height = props.height || 25
  return (
    <div
      className={`d-flex flex-row mb-4 mb-sm-0 ${socialMedia} ${
        props.fullWidth ? 'justify-content-between' : ''
      }`}
    >
      <a
        href="https://www.instagram.com/qnips.gmbh/?hl=de"
        target="_blank"
        rel="noreferrer"
      >
        <img src={instagramIcon} alt="Instagram" height={height}></img>
      </a>
      <a
        href="https://www.facebook.com/qnips/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={facebookIcon} alt="Facebook" height={height}></img>
      </a>
      <a
        href="https://www.linkedin.com/company/qnips-gmbh/?originalSubdomain=de"
        target="_blank"
        rel="noreferrer"
      >
        <img src={linkedinIcon} alt="LinkedIn" height={height}></img>
      </a>
      <a
        href="https://www.xing.com/companies/qnipsgmbh"
        target="_blank"
        rel="noreferrer"
      >
        <img src={xingIcon} alt="Xing" height={height}></img>
      </a>
    </div>
  )
}
