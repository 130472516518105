import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import useContent from '../../../hooks/useContent'
import SocialMedia from '../social-media/socialMedia'
import TextArea from '../text-area/textArea'
import TextInput from '../text-input/textInput'
import {
  button,
  descriptionText,
  error,
  formBox,
  infoBox,
  infoList,
  loading,
  successIcon,
} from './contactForm.module.scss'

export default function ContactForm(props) {
  const { contact } = useContent()

  const [surname, setSurname] = useState('')
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [inquiry, setInquiry] = useState('')
  const [countrycode, setCountrycode] = useState('')
  const [phone, setPhone] = useState('')

  const [formState, setFormState] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const formSubmit = e => {
    e.preventDefault()
    setIsLoading(true)

    const xhr = new XMLHttpRequest()

    xhr.onreadystatechange = function () {
      if (this.readyState !== 4) return

      if (this.status === 200) {
        setFormState('submitted')
      } else {
        setFormState('error')
      }
      setIsLoading(false)
    }

    xhr.open(
      'POST',
      'https://europe-west1-qnips-io.cloudfunctions.net/SendEmail',
      true
    )
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(
      JSON.stringify({
        data: {
          name: name,
          surname: surname,
          email: email,
          phone: phone ? '+' + countrycode + phone : null,
          message: inquiry,
        },
      })
    )
  }

  return (
    <>
      <div className={infoBox}>
        <h2 className="mb-4">{contact.infoHeader}</h2>
        <div
          className="mb-5 fw-light d-none d-lg-block"
          dangerouslySetInnerHTML={{ __html: contact.infoTextHtml }}
        ></div>
        <div className="container-fluid">
          <div className="row">
            <div
              className={`${infoList} mb-4 mb-xl-5 col-12 col-lg-6 col-xl-12 d-flex align-items-end`}
              dangerouslySetInnerHTML={{ __html: contact.infoListHtml }}
            ></div>
            <div className="flex-row align-items-end d-none d-lg-flex col-6 col-xl-12 mb-4 mb-xl-0">
              <GatsbyImage
                image={
                  contact.imageContactPerson.childImageSharp.gatsbyImageData
                }
                alt={contact.imageContactPersonDescription}
                className="flex-shrink-0"
                style={{
                  width: '160px',
                  height: '160px',
                  borderRadius: '80px',
                }}
              />
              <div
                className="ps-5 fw-light"
                dangerouslySetInnerHTML={{
                  __html: contact.infoContactPersonHtml,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div className={`${formBox} ${isLoading ? loading : ''}`}>
        {formState !== 'submitted' ? (
          <>
            <h2>{contact.formHeader}</h2>
            <div
              className={`mb-4 d-none d-lg-block ${
                formState === 'error' ? error : ''
              }`}
              dangerouslySetInnerHTML={{
                __html:
                  formState !== 'error'
                    ? contact.formTextHtml
                    : contact.formErrorHtml,
              }}
            ></div>
            <form onSubmit={e => formSubmit(e)}>
              <Container fluid className="px-0 mb-4">
                <Row>
                  <Col lg="6">
                    <TextInput
                      placeholder={contact.formName}
                      value={name}
                      onChange={e => setName(e.target.value)}
                      required={true}
                    />
                  </Col>
                  <Col lg="6">
                    <TextInput
                      placeholder={contact.formSurname}
                      value={surname}
                      onChange={e => setSurname(e.target.value)}
                      required={true}
                    />
                  </Col>
                  <Col lg="12">
                    <TextInput
                      type="email"
                      placeholder={contact.formMail}
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      required={true}
                    />
                  </Col>
                  <Col xs="3" lg="2">
                    <TextInput
                      type="tel"
                      prefix="+"
                      ariaLabel="Country Code"
                      value={countrycode}
                      onChange={e => setCountrycode(e.target.value)}
                    />
                  </Col>
                  <Col xs="9" lg="10">
                    <TextInput
                      type="tel"
                      placeholder={contact.formPhone}
                      value={phone}
                      onChange={e => setPhone(e.target.value)}
                    />
                  </Col>
                  <Col lg="12">
                    <TextArea
                      label={`${contact.formInquiry}*`}
                      value={inquiry}
                      onChange={e => setInquiry(e.target.value)}
                      required={true}
                    />
                  </Col>
                </Row>
              </Container>
              <div
                className={`fw-light mb-3 ${descriptionText}`}
                dangerouslySetInnerHTML={{ __html: contact.formInfoTextHtml }}
              ></div>
              <p className={`fw-light ${descriptionText}`}>
                *{contact.formRequiredField}
              </p>
              <div className="d-flex flex-row justify-content-end">
                <Button
                  className={button}
                  variant="outline-secondary"
                  size="lg"
                  type="submit"
                >
                  {contact.formSubmit}
                </Button>
              </div>
            </form>
          </>
        ) : (
          <div className="d-flex flex-column justify-content-center align-items-center my-56">
            <div className={`mb-56 ${successIcon}`}></div>
            <h2 className="mb-5">{contact.successHeader}</h2>
            <div
              className="mb-4"
              dangerouslySetInnerHTML={{ __html: contact.successTextHtml }}
            ></div>
            <SocialMedia />
          </div>
        )}
      </div>
    </>
  )
}
