import React from 'react'
import Footer from './footer/footer'
import Header from './header/header'
// import BgImage from './bgimage'
import { ParallaxProvider } from 'react-scroll-parallax'
import ContentProvider from './content-provider/contentProvider'
import {
  content,
  contentContainer,
  layout,
  noiseContainer,
  overflowHidden,
} from './layout.module.scss'
import ModalContainer from './modal/modal-container/modalContainer'

export default function Layout({ data, children, location }) {
  if (location.pathname.includes('/offline-plugin-app-shell-fallback'))
    return null

  const onSustainabilityPage = location.pathname.includes('/sustainability')

  return (
    <ParallaxProvider>
      <ContentProvider content={data}>
        <div
          className={`${layout} ${!onSustainabilityPage ? overflowHidden : ''}`}
        >
          <div className={noiseContainer}></div>
          <ModalContainer>
            <div className={contentContainer}>
              <Header />
              <div className={`${content} d-flex flex-column gap-6 gap-md-7`}>
                {children}
              </div>
              <Footer></Footer>
            </div>
          </ModalContainer>
        </div>
      </ContentProvider>
    </ParallaxProvider>
  )
}
